'use strict'

const CONSTANTS = require('../constants/constants');

const PageInfoHelper = require('../helpers/pageInfoHelper');
const FlutterHelper = require('../helpers/flutterHelper');

class ContextAnalytics{

    constructor(eventData, locale){
        this.eventData = eventData;
        this.locale = locale;
    }

    pushEvent(){

        //STEP 1: Send userId property by using specific handler
        FlutterHelper.sendProperties( {
            userId : this.eventData.userId || '',
            Login : this.eventData.Login || '',
            customerGroup_user : this.eventData.customerGroup_user || '',
            accountNumber : this.eventData.accountNumber || '',
            cardVirtualNumber : this.eventData.cardVirtualNumber || '',
            ntransactions : this.eventData.ntransactions || ''
        });

        //STEP 2: Send context event by purging event data from unnecessary objects
        PageInfoHelper.attachPageInfo(this.eventData, this.locale);
        delete this.eventData['ecommerce'];

        FlutterHelper.sendEvent(CONSTANTS.ANALYTICS_EVENTS.CONTEXT.BASE_NAME, {
            parameters : this.eventData
        });
    }

}

module.exports = ContextAnalytics;