'use strict'

/**
 * Function used to compute page info and attach them to event body or data dictionary
 * @param data
 * @param currentLocale
 */
function attachPageInfo(data, currentLocale){
    if (!currentLocale)
        return;

    currentLocale = currentLocale.toLowerCase().replace('_', '-');
    var currentUrl = window.location.href.split(currentLocale);

    data.screen_name = currentUrl[1] ? ('/' + currentLocale + currentUrl[1]) : '/';
    data.screen_class = document.title;
}

module.exports = {
    attachPageInfo : attachPageInfo
}