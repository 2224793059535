'use strict'

const ArrayBuilder = require('../helpers/arrayBuilder');
const FlutterHelper = require('../helpers/flutterHelper');

const CONSTANTS = require('../constants/constants');

class ProductImpressionAnalytics{

    constructor(eventData){
        this.eventData = eventData;
    }

    pushEvent(){

        var impressions = this.eventData.ecommerce.impressions;
        if (impressions.length < 1)
            return false;

        var itemListId = impressions[0].categoryid;
        var itemListName = impressions[0].list;
        var items = ArrayBuilder.getItemArray(impressions);

        FlutterHelper.sendEvent(CONSTANTS.ANALYTICS_EVENTS.PRODUCT_IMPRESSION,{
            itemListId: itemListId,
            itemListName: itemListName,
            items: items
        });
    }

}

module.exports = ProductImpressionAnalytics;