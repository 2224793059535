'use strict'

const CONSTANTS = require('../constants/constants');

const ArrayBuilder = require('../helpers/arrayBuilder');
const FlutterHelper = require('../helpers/flutterHelper');

class AddToCartAnalytics{

    constructor(eventData){
        this.eventData = eventData;
    }

    pushEvent(){

        var products = this.eventData.ecommerce.add.products;
        if (products && products.length < 1)
            return false;

        var value = Number(parseFloat(products[0].price).toFixed(2));
        var currency = this.eventData.ecommerce.currencyCode;
        var items = ArrayBuilder.getItemArray(products, true);

        // LOYALTY APP CART EVENT
        var mobileCart = require('../../mobileapp/mobile-cart');
        mobileCart.sendEvent();

        FlutterHelper.sendEvent(CONSTANTS.ANALYTICS_EVENTS.ADD_TO_CART,{
            currency: currency,
            value: value,
            items: items
        });
    }

}

module.exports = AddToCartAnalytics;