'use strict'

const CONSTANTS = require('../constants/constants');

const ArrayBuilder = require('../helpers/arrayBuilder');
const FlutterHelper = require('../helpers/flutterHelper');

class CartAnalytics{

    constructor(eventData){
        this.eventData = eventData;
    }

    pushEvent(){

        var products = this.eventData.ecommerce.cart.products;
        if (products && products.length < 1)
            return false;

        var value = 0;
        products.forEach(product => {
            value += parseFloat(product.price);
        });
        if (value)
            value = Number(value.toFixed(2)); //Casting to number
        var svn1Value = this.eventData.ecommerce.cart.svn1;
        var sv9Value = this.eventData.ecommerce.cart.sv9;
        var currency = this.eventData.ecommerce.cart.currencyCode;
        var items = ArrayBuilder.getItemArray(products, true);

        FlutterHelper.sendEvent(CONSTANTS.ANALYTICS_EVENTS.CART,{
            currency: currency,
            value: value,
            sv9: sv9Value,
            svn1: svn1Value,
            items: items
        });
    }

}

module.exports = CartAnalytics;