'use strict'

const CONSTANTS = require('../constants/constants');
const ProductImpressionAnalytics = require('../concrete/productImpressionAnalytics');
const ProductClickAnalytics = require('../concrete/productClickAnalytics');
const ProductDetailAnalytics = require('../concrete/productDetailAnalytics');
const AddToCartAnalytics = require('../concrete/addToCartAnalytics');
const CartAnalytics = require('../concrete/cartAnalytics');
const CheckoutAnalytics = require('../concrete/checkoutAnalytics');
const PurchaseAnalytics = require('../concrete/purchaseAnalytics');
const ContextAnalytics = require('../concrete/contextAnalytics');

class MobileAnalyticsFactory{

    constructor(){
        //If needed add here factory attributes
    }

    getConcreteAnalyticsBuilder(eventName, eventData, currentLocale){
        switch(eventName){
            case CONSTANTS.ANALYTICS_EVENTS.PRODUCT_IMPRESSION:
                return new ProductImpressionAnalytics(eventData);
            case CONSTANTS.ANALYTICS_EVENTS.PRODUCT_CLICK:
                return new ProductClickAnalytics(eventData);
            case CONSTANTS.ANALYTICS_EVENTS.PRODUCT_DETAIL:
                return new ProductDetailAnalytics(eventData);
            case CONSTANTS.ANALYTICS_EVENTS.ADD_TO_CART:
                return new AddToCartAnalytics(eventData);
            case CONSTANTS.ANALYTICS_EVENTS.CART:
                return new CartAnalytics(eventData);
            case CONSTANTS.ANALYTICS_EVENTS.CHECKOUT.EVENT:
                return new CheckoutAnalytics(eventData);
            case CONSTANTS.ANALYTICS_EVENTS.PURCHASE.EVENT:
                return new PurchaseAnalytics(eventData);
            default: //Default is used to context data layer info
                return new ContextAnalytics(eventData, currentLocale);
        }
    }

}

module.exports = MobileAnalyticsFactory;
