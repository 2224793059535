'use strict'

const ANALYTICS_EVENTS = {
    PRODUCT_IMPRESSION : 'productImpression',
    PRODUCT_CLICK : 'productClick',
    PRODUCT_DETAIL : 'detail',
    ADD_TO_CART : 'addToCart',
    CART : 'cart',
    CHECKOUT : {
        EVENT : 'checkout',
        STEPS : {
            FIRST : 'begin_checkout',
            SECOND : 'add_shippingInfo',
            THIRD : 'add_billingInfo',
            FOURTH : 'add_paymentInfo',
            FIFTH : 'order_review'
        }
    },
    PURCHASE : {
        EVENT : 'purchase',
        REDIRECT_EVENT : 'order_completed'
    },
    CONTEXT : {
        EVENT : 'contextualInformation',
        BASE_NAME : 'screen_view'
    }
}

const HANDLERS = {
    EVENTS : 'analyticsEvents',
    PROPERTIES : 'analyticsProperties'
}

module.exports = {
    ANALYTICS_EVENTS : ANALYTICS_EVENTS,
    HANDLERS : HANDLERS
}