'use strict'

const CONSTANTS = require('../constants/constants');

const ArrayBuilder = require('../helpers/arrayBuilder');
const FlutterHelper = require('../helpers/flutterHelper');

class ProductClickAnalytics{

    constructor(eventData){
        this.eventData = eventData;
    }

    pushEvent(){

        var products = this.eventData.ecommerce.click.products;
        if (products && products.length < 1)
            return false;

        var itemListId = products[0].categoryid;
        var itemListName = products[0].category;
        var items = ArrayBuilder.getItemArray(products);

        FlutterHelper.sendEvent(CONSTANTS.ANALYTICS_EVENTS.PRODUCT_CLICK,{
            itemListId: itemListId,
            itemListName: itemListName,
            items: items
        });
    }

}

module.exports = ProductClickAnalytics;