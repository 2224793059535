'use strict'

/**
 * Function used to return a map of mobile app analytics products
 */
function getItemArray(products, addAdditionalProductData){
    if (!products || products.length < 1)
        return [];

    return products.map(product => {
        let data = {
            itemId: product.id,
            itemName: product.name,
            itemCategory: product.category,
            itemVariant: product.variant,
            itemBrand: product.brand,
            price: product.price ? Number(parseFloat(product.price).toFixed(2)) : null,
            sv8: product.sv8
        }

        if(addAdditionalProductData){
            data.quantity = product.quantity;
            data.SKU = product.SKU;
        }

        return data;
    });
}

module.exports = {
    getItemArray : getItemArray
}