'use strict'

const CONSTANTS = require('../constants/constants');

const ArrayBuilder = require('../helpers/arrayBuilder');
const FlutterHelper = require('../helpers/flutterHelper');

class PurchaseAnalytics{

    constructor(eventData){
        this.eventData = eventData;
    }

    pushEvent(){

        var products = this.eventData.ecommerce.purchase.products;
        if (products && products.length < 1)
            return false;

        var value = Number(parseFloat(this.eventData.ecommerce.purchase.actionField.revenue).toFixed(2));
        var shipping = Number(parseFloat(this.eventData.ecommerce.purchase.actionField.shipping).toFixed(2));
        var coupon = this.eventData.ecommerce.purchase.actionField.coupon ? this.eventData.ecommerce.purchase.actionField.coupon : false;
        var tax = Number(parseFloat(this.eventData.ecommerce.purchase.actionField.tax).toFixed(2));
        var transactionID = this.eventData.ecommerce.purchase.actionField.id;
        var affiliation = '';
        var items = ArrayBuilder.getItemArray(products, true);

        FlutterHelper.sendEvent(CONSTANTS.ANALYTICS_EVENTS.PURCHASE.EVENT,{
            currency: 'EUR',
            value: value,
            shipping : shipping,
            coupon : coupon,
            tax : tax,
            transactionID : transactionID,
            affiliation : affiliation,
            items: items
        }, true);
    }

}

module.exports = PurchaseAnalytics;