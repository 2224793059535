'use strict';
window.jQuery = require('jquery');
window.$ = require('jquery');

/**
 * Function that wraps dataLayer.push for Google Analytics
 * If mobile application is used, dataLayer.push is avoided, and in app analyticsEvent will be triggered to push events
 * @param eventName
 * @param data
 * @param forcePushToApp
 */
function pushEvents(eventName, data, forcePushToApp, currentLocale){
    try{

        //STEP 0: Attach factory instance
        if(!window.factoryInstance){
            const MobileAnalyticsFactory = require('./factories/mobileAnalitycsFactory');
            window.factoryInstance = new MobileAnalyticsFactory();
        }

        if((forcePushToApp && forcePushToApp === 'true') || $('body > .js-loyaltyApp-dataset').data('loyaltyapp')){
            //STEP 1: Get concrete builder from factory
            var concreteBuilder = window.factoryInstance.getConcreteAnalyticsBuilder(eventName, data, currentLocale);

            //STEP 2: Execute push event function
            if(concreteBuilder)
                concreteBuilder.pushEvent();
        }
        // else if(!eventName)
        //     dataLayer.push(data);
        // else
        //     dataLayer.push({
        //         event: eventName,
        //         ecommerce: data.ecommerce
        //     });
    }
    catch (e) {
        console.error('Something wrong happened during event capture: ' + e.message);
    }
}

if(!window.analyticsWrapper){
    window.analyticsWrapper = pushEvents;
}


