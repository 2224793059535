'use strict'

const CONSTANTS = require('../constants/constants');

const ArrayBuilder = require('../helpers/arrayBuilder');
const FlutterHelper = require('../helpers/flutterHelper');

class CheckoutAnalytics {

    constructor(eventData) {
        this.eventData = eventData;
    }

    pushEvent() {

        var products = this.eventData.ecommerce.checkout.products;
        if (products && products.length < 1)
            return false;

        var currency = 'EUR';
        var value = 0;
        products.forEach(product => {
            value += parseFloat(product.price);
        });
        if (value)
            value = Number(value.toFixed(2));
        var items = ArrayBuilder.getItemArray(products, true);

        var actionField = this.eventData.ecommerce.checkout.actionField;
        var step = actionField.step.toString();
        var eventBody, eventName;
        switch (step) {
            case '1':
                eventName = CONSTANTS.ANALYTICS_EVENTS.CHECKOUT.STEPS.FIRST;
                eventBody = {
                    sv9: actionField.sv9,
                    svn1: actionField.svn1,
                    step: 1,
                    currency: currency,
                    value: value,
                    items: items
                }
                break;
            case '2':
                eventName = CONSTANTS.ANALYTICS_EVENTS.CHECKOUT.STEPS.SECOND;
                eventBody = {
                    sv9: actionField.sv9,
                    svn1: actionField.svn1,
                    sv4: actionField.sv4,
                    sv5: actionField.sv5,
                    sv6: actionField.sv6,
                    sv7: actionField.sv7,
                    step: 2,
                    currency: currency,
                    value: value,
                    items: items
                }
                break;
            case '3':
                eventName = CONSTANTS.ANALYTICS_EVENTS.CHECKOUT.STEPS.THIRD;
    
                eventBody = {
                    sv9: actionField.sv9,
                    svn1: actionField.svn1,
                    step: 3,
                    currency: currency,
                    value: value,
                    items: items
                }
                break;
            case '4':
                eventName = CONSTANTS.ANALYTICS_EVENTS.CHECKOUT.STEPS.FOURTH;
                if (!actionField.sv11 || actionField.sv11 === '')
                    return;
                eventBody = {
                    sv9: actionField.sv9,
                    svn1: actionField.svn1,
                    paymentType: actionField.sv11,
                    step: 4,
                    currency: currency,
                    value: value,
                    items: items
                }
                break;
        }

        FlutterHelper.sendEvent(eventName, eventBody);
    }

}

module.exports = CheckoutAnalytics;