'use strict'

var mobileHP = {
    init: function () {
        // $(document).ready(function() {

        //     $('.start-slick-slider').slick({
        //         arrows: false,
        //         dots: true,
        //         slidesToShow: 1.1,
        //         infinite: false
        //     });
        // });

        var context = this;


        $('.js-login-loyaltyapp').on('click', function(event){
            event.preventDefault();
            context.sendEvent('login_event');
        });

        $('.js-signup-loyaltyapp').on('click', function(event) {
            event.preventDefault();
            context.sendEvent('signup_event');
        });

        $('.js-confirmEmail-loyaltyapp').on('click', function(event) {
            event.preventDefault();
            context.sendEvent('confirm_event');
        });

        $('.js-discover-loyaltyapp').on('click', function(event) {
            event.preventDefault();
            context.sendEvent('discover_more_event');
        });

        // $('#mobile-slider').removeClass('d-none');
        // $('#mobile-slider-skeleton').addClass('d-none');



        $('.mobileapp-header .close-search').on('click', function() {
            $('#main').removeClass('d-none');
            $('header.mobileapp-header').removeClass('fixed').removeClass('header');
        });

        $('.mobileapp-header .search-mobileapp').find('input[name="q"]').keyup(function () {
            $('header.mobileapp-header').addClass('fixed').addClass('header');
            $('#main').addClass('d-none');
        });

        $('.arrow-come-back').on('click', function() {
            $(document).trigger('click_and_collect_show');
            $('html').removeClass('no-overflow');
            var searchMenu = $('.js-search-menu');
            var arrow = $(this);
            var selected = $('.name-category-selected').data('name-selected');
            $('.subcategory-container').filter(function(){
                return $(this).data("cgid") === selected}).removeClass('no-events');
            $('#navigation.mobileapp-header').removeClass('animation-left').addClass('animation-disappear-left');
            searchMenu.removeClass('animation-left').addClass('animation-disappear-left');

            searchMenu.on('animationend', function() {
                $(this).addClass('d-none');
                arrow.addClass('d-none');
                $('.wrapper-subcategories').removeClass('animation-right');
                $('#main').removeClass('d-none');
                $('.mobile-header-promotion').removeClass('d-none');
                $(this).find('.menu-subcategories-level2').html('');
                $(this).off('animationend');
            });
        });

        // initSlick();
    },

    sendEvent: function (msg){
        var data = {
            event_type : msg
        }

        try{
            if(window.flutter_inappwebview){
                window.flutter_inappwebview.callHandler('navigation_event_handler', data);
            }
        } catch (e) {
            console.error("Impossbile to execute firebase call handler!", e)
        }
    }
}

function initSlick(){
    var container = $('.wrap-container .slick-container');
    var startSlick = $('.wrap-container .slick-container .start-slick-slider');
    var elementContainer = $('.wrap-container .slick-container .start-slick-slider .slick-element-container');
    var image = $('.wrap-container .slick-container .start-slick-slider .slick-element-container img');
    container.height = (container.width * 0.35) + 70;
    startSlick.height = (startSlick.width * 0.35) + 70;
    elementContainer.height = (elementContainer.width * 0.35);
    image.height = (image.width * 0.35);
}

module.exports = mobileHP;