'use strict'

const CONSTANTS = require('../constants/constants');

/**
 * This function pushes every event, and it's used to push contextual information too (mobile app constraint)
 * @param eventName
 * @param eventBody
 */
function sendEvent(eventName, eventBody, resetCart){
    if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler){
        window.flutter_inappwebview.callHandler(CONSTANTS.HANDLERS.EVENTS, {
            event : eventName,
            body : eventBody
        });
        if (resetCart)
            resetCartRoutine();
    }
    else {
        console.warn('Flutter in app webview not ready or not available: fallback procedure initialization...');
        window.addEventListener('flutterInAppWebViewPlatformReady', function(event){
            window.flutter_inappwebview.callHandler(CONSTANTS.HANDLERS.EVENTS, {
                event : eventName,
                body : eventBody
            });
            if (resetCart)
                resetCartRoutine();
        });
    }
}

/**
 * Call this function only to push user id (mobile app constraint)
 * In future, it may be used to push new user properties data
 * @param eventName
 * @param eventBody
 */
function sendProperties(eventBody){
    if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler)
        window.flutter_inappwebview.callHandler(CONSTANTS.HANDLERS.PROPERTIES, {
            body : eventBody
        });
    else {
        console.warn('Flutter in app webview not ready or not available: fallback procedure initialization...');
        window.addEventListener('flutterInAppWebViewPlatformReady', function(event){
            window.flutter_inappwebview.callHandler(CONSTANTS.HANDLERS.PROPERTIES, {
                body : eventBody
            });
        });
    }
}

function resetCartRoutine(){
    const MobileHomepageHelper = require('../../mobileapp/mobile-homepage');

    //STEP EXTRA: Handle mobile application redirect by sending an appropriate event.
    var appMobileOrderConfirmed = $('.js-order-confirmed');
    if(appMobileOrderConfirmed.length > 0)
        setTimeout(function() { //This timeout is needed by mobile app: please DO NOT remove
                MobileHomepageHelper.sendEvent(CONSTANTS.ANALYTICS_EVENTS.PURCHASE.REDIRECT_EVENT);
        }, 2000);
}

module.exports = {
    sendEvent : sendEvent,
    sendProperties : sendProperties
}