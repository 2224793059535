'use strict'

const ArrayBuilder = require('../helpers/arrayBuilder');
const FlutterHelper = require('../helpers/flutterHelper');

const CONSTANTS = require('../constants/constants');

class ProductDetailAnalytics{

    constructor(eventData){
        this.eventData = eventData;
    }

    pushEvent(){

        var products = this.eventData.ecommerce.detail.products;
        if (products && products.length < 1)
            return false;

        var currency = this.eventData.ecommerce.currencyCode;
        var value = Number(parseFloat(products[0].price).toFixed(2));
        var items = ArrayBuilder.getItemArray(products);

        //An array must be built even if there will be only one product. This is an event structure constraint
        FlutterHelper.sendEvent(CONSTANTS.ANALYTICS_EVENTS.PRODUCT_DETAIL,{
            currency: currency,
            value: value,
            items: items
        });
    }

}

module.exports = ProductDetailAnalytics;